import {
  FormContext,
  FormState,
  PathState,
  useFieldArray as useFieldArrayBase,
  useFieldError as useFieldErrorBase,
} from 'vee-validate'
import { cloneDeep, escapeRegExp, get, isEqual, set, zip } from 'lodash-es'
import { Ref, computed, nextTick, ref, toValue } from 'vue'
import { unrefElement } from '@vueuse/core'

import { KeyOf, Path } from '../../types'
import { nextFrame, objAssignSkipUndefined, objEntries, objFromEntries, objKeys, truthy } from '../../utils'

import { useFormEvents } from './useFormEvents'
import {
  FallbackErrorMessage,
  GetFieldValue,
  GetId,
  IsFieldDirty,
  ResetField,
  ResetForm,
  ScrollToError,
  SetFieldValue,
  SetInitialValue,
  UseFieldArray,
  UseFieldArrayModels,
  UseFieldError,
  UseFieldModel,
  UseFieldModels,
  UseFieldModelsStartingWith,
  UseFormAddonsContext,
  UseFormAddonsHandleSubmitFactory,
  UseFormAddonsReturn,
  ValidateField,
} from './types'

const fallbackErrorMessageGetter = (errors: Record<string, string>): string => {
  const errorMessage = objEntries(errors).map(([key, value]) => `${key}: ${value}`).join(', ')

  return `Please resolve form errors. ${errorMessage}`
}

export const useFormAddons = <ValuesInput extends Record<string, unknown>, ValuesOutput extends Record<string, unknown>>(
  form: FormContext<ValuesInput, ValuesOutput>,
  { initialValues, onMissingField, fallbackErrorMessage: _fallbackErrorMessage = fallbackErrorMessageGetter }: {
    initialValues: Ref<ValuesInput>
    onMissingField: (message: string) => void
    fallbackErrorMessage?: FallbackErrorMessage
  },
): UseFormAddonsReturn<ValuesInput, ValuesOutput> => {
  // This is undocumented but it's the only way to get all instantiated fields
  const pathStates = computed(() => (form as any).getAllPathStates() as Array<PathState<unknown>>)

  const isMatchingPath = (path: string, subPath: string): boolean => {
    return subPath === path || ['.', '['].some(separator => subPath.startsWith(`${path}${separator}`))
  }

  // Since we use nested field paths, we need to get all states that start with the path
  const getStatesFromPath = (path: KeyOf<ValuesInput>) => {
    return pathStates.value.filter(state => isMatchingPath(path, state.path))
  }

  const setInitialValueInternal = (field: string, value: any) => {
    const values = cloneDeep(initialValues.value)
    set(values, field, cloneDeep(value))

    initialValues.value = values
  }

  const resetField: ResetField<ValuesInput> = (field, state = {}) => {
    if ('value' in state) {
      setInitialValueInternal(field as Path<ValuesInput>, state.value)
    }

    // vee-validate's Path type is incorrect for array fields
    form.resetField(field as any, state)
  }

  const resetForm: ResetForm<ValuesInput> = (state = {}) => {
    if (state.values !== undefined) {
      initialValues.value = objAssignSkipUndefined({}, initialValues.value, cloneDeep(state.values))
    }

    form.resetForm(state as FormState<ValuesInput>)
  }

  const setFieldValue: SetFieldValue<ValuesInput> = (field, value) => {
    form.setFieldValue(field as any, value as any, true)
  }

  const getFieldValue: GetFieldValue<ValuesInput> = (field) => {
    return get(form.values, field) as any
  }

  const setInitialValue: SetInitialValue<ValuesInput> = (field, value) => {
    const currentInitialValue = get(initialValues.value, field)
    const currentValue = get(form.values, field)
    setInitialValueInternal(field, value)

    if (isEqual(currentInitialValue, currentValue)) {
      setFieldValue(field, value)
    }
  }

  const getInitialValue: GetFieldValue<ValuesInput> = (field) => {
    return get(initialValues.value, field) as any
  }

  const validateField: ValidateField<ValuesInput> = async (field) => {
    const result = await form.validateField(field as any)
    await Promise.all(
      getStatesFromPath(field).map(async (subField) => {
        await form.validateField(subField.path as any)
      }),
    )
    await form.validate({ mode: 'validated-only' })

    return result
  }

  /**
   * Fixed version of useFieldModel that always validates on set
   * This was broken in vee-validate in this commit
   * https://github.com/logaretm/vee-validate/commit/f9a95843d4edff80c2b7ed203221c74178f815e0#diff-62558eea84b65090b7f10119a7aedd14bd7be247c66e8ed7dfe6085dc3abbca2R1046
   */
  const useFieldModel: UseFieldModel<ValuesInput> = (path) => {
    const models = form.useFieldModel([path] as any[])

    return models[0]! as any
  }

  const useFieldModels: UseFieldModels<ValuesInput> = (paths) => {
    const fields = form.useFieldModel(paths as any)

    return objFromEntries(
      zip(paths, fields as any)
        .map(([path, field]) => {
          return (path && field)
            /* v8 ignore next 2 */
            ? [path, field] as const
            : null
        })
        .filter(truthy),
    ) as any
  }

  const useFieldModelsStartingWith: UseFieldModelsStartingWith<ValuesInput> = (startingPath, paths) => {
    const fields = useFieldModels(paths.map(path => `${startingPath}.${path}` as Path<ValuesInput>))
    const startingPathRegex = new RegExp(`^${escapeRegExp(startingPath)}\.`)

    return objFromEntries(
      objEntries(fields).map(([path, field]) => [path.replace(startingPathRegex, ''), field] as const),
    ) as any
  }

  const useFieldArray: UseFieldArray<ValuesInput> = useFieldArrayBase
  const useFieldError: UseFieldError<ValuesInput> = useFieldErrorBase

  const useFieldArrayModels: UseFieldArrayModels<ValuesInput> = (path, paths, index) => {
    const getArrayItem = (): any => get(form.values, `${path}[${toValue(index)}]`)

    return objFromEntries(
      paths.map((subPath) => {
        return [subPath, computed({
          get: () => getArrayItem()[subPath],
          set: (value) => {
            setFieldValue(`${path}[${toValue(index)}]` as Path<ValuesInput>, {
              ...getArrayItem(),
              [subPath]: value,
            })
          },
        })]
      }),
    )
  }

  const isFieldDirty: IsFieldDirty<ValuesInput> = (path) => {
    return !isEqual(get(form.values, path), get(initialValues.value, path))
  }

  const getId: GetId<ValuesInput> = path => path

  const pending = computed(() => form.meta.value.pending)
  const valid = computed(() => form.meta.value.valid)

  // Because of bugs with initialValues in useForm, we can't use form.meta.dirty
  const dirty = computed(() => !isEqual(form.values, initialValues.value))

  const submitting = form.isSubmitting
  const validating = form.isValidating

  const formRef = ref<HTMLElement>()
  const formEvents = useFormEvents()

  const scrollToError: ScrollToError = async ({
    errorFields,
    fallbackErrorMessage = _fallbackErrorMessage,
    fallbackOnNoErrors = false,
  } = {}) => {
    const errors = errorFields ?? objKeys(form.errors.value)

    if (errors.length === 0 && !fallbackOnNoErrors) {
      return
    }

    formEvents.validationError.trigger()

    // Ensure validation error event has propagated
    await nextTick()

    // Ensure form has been rerendered in response to the validation error event
    await nextFrame()

    const formEl = unrefElement(formRef)
    const element = errors.map((field) => {
      return formEl?.querySelector<HTMLElement>(`[id='${field}'], [name='${field}'], [for='${field}']`)
    }).find(truthy)

    if (element) {
      element.scrollIntoView({ block: 'center' })
    }
    else {
      const errorsRecord = objFromEntries(
        errors.map((key) => {
          const error = (form.errors.value as Record<string, string>)[key]

          return error
            /* v8 ignore next 2 */
            ? [key, error] as const
            : null
        }).filter(truthy),
      )
      const errorMessage = typeof fallbackErrorMessage === 'function'
        ? fallbackErrorMessage(errorsRecord)
        : fallbackErrorMessage
      onMissingField(errorMessage)
    }
  }

  const handleSubmit: UseFormAddonsHandleSubmitFactory<ValuesInput, ValuesOutput> = (onSuccess, onError) => {
    return form.handleSubmit(
      values => onSuccess(values),
      (context) => {
        onError?.(context)
        scrollToError()
      },
    )
  }

  const context: UseFormAddonsContext<ValuesInput> = {
    resetField,
    validateField,
    useFieldModel,
    useFieldModels,
    useFieldModelsStartingWith,
    useFieldArray,
    useFieldArrayModels,
    useFieldError,
    isFieldDirty,
    getId,
    submitting,
    validating,
    setFieldValue,
    getFieldValue,
    setInitialValue,
    getInitialValue,
  }

  return {
    pathStates,
    isMatchingPath,
    getStatesFromPath,

    resetForm,
    handleSubmit,
    pending,
    dirty,
    valid,
    formRef,
    scrollToError,
    context,
    initialValues,
    ...context,
  }
}
